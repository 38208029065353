<template>
  <div>
    <div class="mb-2 items-center">
      <vs-button class="ml-auto" @click="popupAddItem = true; editMode = false" size="small">Add New Bulletin</vs-button>
    </div>
    <vx-card no-shadow>
      <vs-table
        :max-items="descriptionItems[0]"
        pagination
        :data="bulletins"
        description
        search
        :description-items="descriptionItems"
        description-title="Registries">

        <template slot="header">
          <h5 class="flex items-center mb-2">
            <feather-icon icon="TagIcon" class="w-5 h-5 mr-1"></feather-icon>
            <span>Bulletins </span>
          </h5>
        </template>
        <template slot="thead">
          <vs-th>
            Start
          </vs-th>
          <vs-th>
            End
          </vs-th>
          <vs-th>
           Message
          </vs-th>
          <vs-th>
            Status
          </vs-th>
          <vs-th>
            Action
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td :data="data[indextr].start">
              {{data[indextr].start | date(true)}}
            </vs-td>
            <vs-td :data="data[indextr].end">
              {{data[indextr].end | date(true)}}
            </vs-td>
            <vs-td :data="data[indextr].message">
              {{data[indextr].message}}
            </vs-td>
             <vs-td :data="data[indextr].is_active">
              <vs-chip :color="getStatusColor(tr.is_active)">{{ tr.is_active? '✓' :'X' }}</vs-chip>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="updateItem(tr)" />
                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteItem(tr.id)" />
              </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-popup class="holamundo" @close="initializeAll" 
      :title="editMode ? 'Edit Bulletin':'Add Bulletin'"
      :active.sync="popupAddItem">
      <bulletin-form v-if="popupAddItem"
        :editItem="editItem"
        :editMode="editMode"
        @closeMe="initializeAll"/>
    </vs-popup>
    </vx-card>
  </div>
</template>

<script>
import BulletinForm from '@/components/forms/BulletinForm.vue'

export default {
  components:{
    BulletinForm
  },
  data() {
    return {
      isLoading: false,
      descriptionItems: [5,15,50],
      editMode: false,
      popupAddItem: false,
      editItem: null,
    }
  },
  computed: {
    bulletins(){
      return this.$store.state.bulletin.list
    }
  },
  methods: {
    fetchItems() {
      this.isLoading = true
      this.$store.dispatch('bulletin/fetchBulletins')
      .then((_) => { this.isLoading = false })
    },
    updateItem(item){
      this.editItem = item;
      this.editMode = true
      this.popupAddItem = true
    },
    deleteItem(itemId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'The selected bulletin will be removed!',
        accept: () => this.acceptDelete(itemId)
      })
    },
    acceptDelete(itemId){
      this.$http.delete(`/bulletins/${itemId}/delete`)
      .then((response) => {
        if(response.data.success){
          this.$store.commit('bulletin/DELETE_ITEM', itemId)
          this.initializeAll();
        }
      })

    },
    initializeAll(){
      this.popupAddItem = false
      this.editMode = false
      this.editItem = null
      this.$validator.reset()
    }
  },
  created(){
    this.fetchItems()
  }
}
</script>
